@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Clearview&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Heading styles */
h1, h2 {
  @apply font-display font-bold;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3, h4, h5, h6 {
  @apply font-body;
}

h3 {
  @apply text-2xl font-display font-semibold;
}

h4 {
  @apply text-xl font-medium;
}

h5 {
  @apply text-lg font-normal;
}

h6 {
  @apply text-base font-normal;
}

.map-marker-label {
  color: #000;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 4px;
}

.plus-code {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #4a5568;
}

.chat-window {
  height: 100%;
  overflow: hidden;
}

.event-content {
  display: flex;
  align-items: center;
}

.event-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}

.event-title {
  font-size: 12px;
}

